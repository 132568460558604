import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
//import { resolve } from 'dns';
import { escape, unescape } from 'querystring';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private storage: Storage) { }

  async store(storageKey: string, value:any){
    const encryptedvalue =  Buffer.from(JSON.stringify(value),'binary').toString('base64');
    return await this.storage.set(storageKey, encryptedvalue)
  }

  async get(storageKey: string){
    return new Promise(resolve => {
      this.storage.get(storageKey).then((value) => {
          if(value == null){
            resolve(false);
          }else{
            resolve(JSON.parse(Buffer.from(value,'base64').toString('binary')));
          }
      })
    }

    );
  }

  async removeItem(storageKey: string){
    await this.storage.remove(storageKey);
  }

  async clear(){
    await this.storage.clear();
  }

}
