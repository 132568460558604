import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { IntroGuard } from './guards/intro.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./pages/walkthrough/walkthrough.module').then( m => m.WalkthroughPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    //canLoad: [AutoLoginGuard] 
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad: [ AuthGuard] 
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FaqsPageModule),
    //canLoad: [ AuthGuard]   
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [ AuthGuard]  
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule),
    canLoad: [ AuthGuard]  
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule),
    canLoad: [ AuthGuard]  
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'interruptions',
    loadChildren: () => import('./pages/interruptions/interruptions.module').then( m => m.InterruptionsPageModule),
    canLoad: [ AuthGuard]  
  },
  {
    path: 'details/:id',
    loadChildren: () => import('./pages/details/details.module').then( m => m.DetailsPageModule),
    canLoad: [ AuthGuard]  
  },
  {
    path: 'reportblackout',
    loadChildren: () => import('./pages/reportblackout/reportblackout.module').then( m => m.ReportblackoutPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'adduserlocation',
    loadChildren: () => import('./pages/adduserlocation/adduserlocation.module').then( m => m.AddUserLocationPageModule),
    canLoad: [ AuthGuard]   
  },
  {
    path: 'buypower',
    loadChildren: () => import('./pages/buypower/buypower.module').then( m => m.BuyPowerPageModule),
    canLoad: [ AuthGuard]  
  },
  {
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
  {
    path: 'tocs',
    loadChildren: () => import('./pages/tocs/tocs.module').then( m => m.TocsPageModule)
  },
  {
    path: 'powerinterruptions',
    loadChildren: () => import('./pages/powerinterruptions/powerinterruptions.module').then( m => m.PowerinterruptionsPageModule)
  }
  


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, initialNavigation: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
