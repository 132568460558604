import { Injectable, NgZone } from '@angular/core';
import { User } from "./user";
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';

import { LoadingController, Platform } from '@ionic/angular';
import { GooglePlus } from '@ionic-native/google-plus/ngx';

import { StorageService } from './storage.service';
import { HttpClient } from '@angular/common/http';

import { map, tap, switchMap, first, take } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';

import { Client } from 'africastalking-ts';

import { PLATFORM_ID , Inject} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { userInfo } from 'os';





const TOKEN_KEY = 'user';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  userData: any;
  public loading: any;
  public isGoogleLogin = false;
  public user = null;

  confirmationResult: firebase.auth.ConfirmationResult;

  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	token = '';

  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,  
    public ngZone: NgZone,
    private google: GooglePlus,
    private platform: Platform,
    private http: HttpClient,
    private storageService: StorageService,
    private analytics: AngularFireAnalytics,
    @Inject(PLATFORM_ID) private platformId: Object    
  ) {

    this.loadToken();
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      this.ngFireAuth.authState.subscribe(user => {
        if (user) {
          this.userData = user;
          localStorage.setItem(TOKEN_KEY, this.userData.uid);
          //JSON.parse(localStorage.getItem(TOKEN_KEY));
        } else {
          localStorage.removeItem(TOKEN_KEY);
          //JSON.parse(localStorage.getItem(TOKEN_KEY));
          
        }
      })
    }
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password)
  }

  // Register user with email/password
  RegisterUser(email, password) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password)
  }

  // Email verification when new user register
  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then(u => u.sendEmailVerification())
    .then(() => {
      this.router.navigate(['verify-email']);
    })
  }

  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email has been sent, please check your inbox.');
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in  
  get isLoggedIn(): boolean {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem(TOKEN_KEY));
      return (user !== null && user.emailVerified !== false) ? true : false;
      }
  }

  // Returns true when user's email is verified
  get isEmailVerified(): boolean {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem(TOKEN_KEY));
      return (user.emailVerified !== false) ? true : false;
    }
  }

  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth providers
  AuthLogin(provider) {
    let params: any;
    if (this.platform.is('cordova')) {
      if (this.platform.is('android')) {
        params = {
          webClientId: '251359210102-tuvmr2ddk5ajemtgjv73mt9vt3119bms.apps.googleusercontent.com', //  webclientID 'string'
          offline: true
        };
      } else {
        params = {};
      }
      this.google.login(params)
      .then((response) => {
        const { idToken, accessToken } = response;
        this.onLoginSuccess(idToken, accessToken);
      }).catch((error) => {
        //console.log(error);
        alert('error:' + JSON.stringify(error));
      });
    } else{
      //console.log('else...');
      return this.ngFireAuth.signInWithRedirect(provider)
      .then((success) => {
        this.ngFireAuth.getRedirectResult().then(result => {
          if (result.user) {
            this.ngZone.run(() => {
              this.router.navigate(['dashboard']);
            })
          this.SetUserData(result.user);
          }
         })

       
    }).catch((error) => {
      window.alert(error)
    })
      
    }
      
  }



  onLoginSuccess(accessToken, accessSecret) {
    const credential = accessSecret ? firebase.auth.GoogleAuthProvider
        .credential(accessToken, accessSecret) : firebase.auth.GoogleAuthProvider
            .credential(accessToken);
    this.ngFireAuth.signInWithCredential(credential)
      .then((success) => {
        alert('successfully');
        this.isGoogleLogin = true;
        this.user =  success.user;
        this.loading.dismiss();
      });

  }

  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
   
   
    return userRef.set(user, {
      merge: true
    })
  }

  // Sign-out 
  SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      this.isGoogleLogin = false;  
      localStorage.removeItem(TOKEN_KEY);
      this.analytics.logEvent('signOut');
      this.router.navigate(['login']);
    }

    })
  }

  // Sign in with phone number
  public signInWithPhoneNumber(recaptchaVerifier, phoneNumber) {
    return new Promise<any>((resolve, reject) => {

      this.ngFireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;

          resolve(confirmationResult);          
          
          this.isAuthenticated.next(true);
          
        }).catch((error) => {
          //console.log(error);
          reject('SMS not sent');
        });
    });
  }

  public async enterVerificationCode(code) {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult.confirm(code).then(async (result) => {
        if (result.user) {
          //confirm browser for SSR purposes
          if (isPlatformBrowser(this.platformId)) {
          
            this.ngZone.run(() => {
             localStorage.setItem(TOKEN_KEY, JSON.stringify(result.user.uid));
              this.isAuthenticated.next(true);
            
              //this.router.navigate(['interruptions']); 
               
              /* this.storageService.store(TOKEN_KEY,this.confirmationResult.verificationId)
              .then((res) =>{
                //console.log(res);
               
              })
              .catch((error) => {
                //console.log(error);
                reject(error);
              }); */

              //console.log("USER LOGIN: " + JSON.stringify(result.user));
              //console.log("===========================================");
              //console.log("NEW USER?: " + JSON.stringify(result.additionalUserInfo.isNewUser));
              //enter user data to DB

              const userData: User = {
                uid: result.user.uid,
                email: result.user.email,
                displayName: result.user.displayName,
                photoURL: result.user.photoURL,
                phoneNumber: result.user.phoneNumber,
                emailVerified: result.user.emailVerified,
                
              }
          
              this.SetUserData(userData);
             
              
             
          })
          
        }
        }
        //console.log("RESULT: " + JSON.stringify(result));
        let user = result ;
        
        //console.log("CONFIMED USER: " + JSON.stringify(user));
        resolve(user);
        
      }).catch((error) => {
        reject(error.message);
      });

    });
  }

  async loadToken() {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      //let token:any = await this.storageService.get(TOKEN_KEY);
      let token:any = await localStorage.getItem(TOKEN_KEY)
      if (token) {
        //console.log('set token: ', token);
        this.token = token;
        this.isAuthenticated.next(true);
      } else {
        this.isAuthenticated.next(false);
      }
   }
	}

  logout(): Promise<void> {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
		this.isAuthenticated.next(false);
    return this.ngFireAuth.signOut().then(() => {
      this.isGoogleLogin = false;  
      localStorage.removeItem(TOKEN_KEY);
      this.router.navigate(['login']);
    })
  }
	}
  autoLogin() {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
    const userData = localStorage.getItem(TOKEN_KEY);
    if (!userData) {
       this.user.next(null);
       return;
    }
    this.user.next(userData);
  }
   }

   
  async getCurrentUserID(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.ngFireAuth.authState
        .pipe(take(1))
        .subscribe(user => {
          if (user) {
            resolve(user.uid);
          } else {
            reject('User not logged in');
          }
        });
    });
  }
  

  

}