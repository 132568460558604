import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage-angular';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

import { AuthenticationService } from "./services/authentication-service";

import { PLATFORM_ID , Inject} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { Title, Meta } from '@angular/platform-browser';  


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {

  public onlineOffline: boolean;
 

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public alertController: AlertController,
    private router: Router,
    public authService: AuthenticationService,
    private metaTagService: Meta
    
  ) {
    //confirm browser for SSR purposes
    if (isPlatformBrowser(this.platformId)) {
      this.initializeApp();
      this.storage.create();

      this.onlineOffline = navigator.onLine;
    }
  }

  ngOnInit() {
    this.metaTagService.addTags([
      { name: 'keywords',content: 'KPLC Power Interuptions and Power blackout near you'},
      {name: 'description', content: 'KPLC Power Interuptions and Power blackout near you'},
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Latima App ' },
      { charset: 'UTF-8' },
    ]);
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.hide();
      this.splashScreen.hide();
      if (!navigator.onLine) {
        //console.log("Offline via naigator");
        this.presentAlert();
        }
      
        window.addEventListener('offline', () => {
          //console.log("Offline via window");
          this.presentAlert();
          });
     
    });
  }

  async presentAlert() {
    //console.log("present alert");
    
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      message: 'Network disconnected! Please check your connection',
      buttons: [{
        text: 'Ok',
        handler: () => {
        //app is closing when user click on ok 
        this.logout() ;
        }
      }]
    });

    alert.present();
   
    
  }

  async logout(): Promise<void> {
		await this.authService.logout();
		this.router.navigateByUrl('/', { replaceUrl: true });
	}
}
