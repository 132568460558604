// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBTLjL7-OVH8CxCi6okkbZrpChWJzVPBCw",
    authDomain: "latimer-4ff43.firebaseapp.com",
    databaseURL: "https://latimer-4ff43-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "latimer-4ff43",
    storageBucket: "latimer-4ff43.appspot.com",
    messagingSenderId: "452971369613",
    appId: "1:452971369613:web:c82552b48649ca8f8cfec4",
    measurementId: "G-M4PQ4JZFXG"
  },
  tandaapiUrl: 'https://io-proxy-443.tanda.co.ke/sandbox',
  tandaclientID: '6ab12c2d-1f47-4b4c-a7a6-a4d2f0f8005b',
  tandaclientSecret: 'mHcwtaTWxSbd9A2rPQWpHSyA',
  tandaorganizationID: '409bac8b-2a8d-420e-8daf-b151a06c242',
  mpesaapiUrl: 'https://sandbox.safaricom.co.ke/oauth/v1',
  mpesaconsumerkey: 'y9yXepXT5M2GCQk9yQnKRuC67V1EaJXq',
  mpesaconsumersecret: 'Qvr3AsNDyeFsl8TS',
  storageToken: 'user',
  appName: 'Latima App',
  appURL: 'https://latima.app/',
 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
