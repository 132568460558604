import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
import { Token} from './token.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import * as moment from 'moment';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { Router } from '@angular/router';


declare var SMS:any;

@Injectable({
  providedIn: 'root'
})
export class DbService {

  tokenList = new BehaviorSubject([]);

  

  constructor(public platform:Platform,public androidPermissions: AndroidPermissions, private router: Router) {

    this.platform.ready().then(async() => {

      if (this.platform.is('cordova')) {
        await this.checkSMSPermission();

      }

    });
   }

   async checkSMSPermission() {
    return new Promise((resolve, reject) => {
       this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_SMS).then(
    result => {
      //console.log(result.hasPermission);
      if (result.hasPermission) {
          //console.log('hasPermission-YES');
            //if permission granted
            this.readTokens();
     } else {
       //console.log('hasPermission-NO');
       //If not having permission ask for permission
       this.requestSMSPermission().then((response) => {
          //console.log(response, 'requestSMSPermission-checkSMSPermission');         
        });
       }
     },
     err => {
       alert(err);
       reject(false);
     });
  });
 }
 async requestSMSPermission() {
    return new Promise((resolve, reject) => {
    
      //console.log('requesting permission');
     //Show 'GPS Permission Request' dialogue
        this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_SMS).then((response) => {
     // call method to turn on GPS
      //console.log(response, 'request granted');  
      if(response.hasPermission){
        this.readTokens();
      }
      else{
        alert('Latima App needs to read SMS messages from KPLC');
        this.router.navigate(['login'])
      }
     
    },
     error => {
      //Show alert if user click on 'No Thanks'
      alert('APP needs to read KPLC SMS' + error);
     reject(false);
     });
    
   
  });
 }




   fetchTokens(): Observable<Token[]> {
        return this.tokenList.asObservable();
  }

  readTokens()
  {
  
    let tokens:any=[];
    let messages:any=[];
    let items: Token[] = [];

  

  
    
    
  
    let filter = {
          box : 'inbox', // 'inbox' (default), 'sent', 'draft'
         indexFrom : 0, // start from index 0
         address : 'KENYA POWER',
         maxCount : 10000 //count of SMS to return each time
              };
        //filter for mpesa this.messages
        
        //alert("start sms");
        //console.log("start sms");
        
        if(SMS) SMS.listSMS(filter, (ListSms)=>{  
         
          
          //console.log("in sms");
          //alert("in sms");
          // list only transactional this.messages
          
          //messages =   ListSms.filter(sms => sms.body.includes("KPLC Token ")); 
          
          
          //alert(JSON.stringify(messages))

         ;
          
          messages =[{_id: 8,
            address: "KENYA POWER",
            body: "KPLC Token \nMtr No: 14140737793  \nToken: 3594-5632-4484-5405-1234\nDate: 2020-04-03 13:33:26 +0300\nUnits kWh:273.41\nAmount Ksh:4500.00\nToken Amount Ksh:4319.95\nVAT: 721.37\nFuel Energy Charge: 683.53\nForex Charge: -31.41\nEPRA Charge: 8.2\nWARMA Charge: 5.82\nREP Charge: 215.99\nInflation Adjustment: 76.55.",
            date: 1547840472000,
            date_sent: 1547840472000,
            error_code: 0,
            ipmsg_id: 0,
            locked: 0,
            m_size: 213,
            person: 0,
            protocol: 0,
            read: 1,
            reply_path_present: 0,
            seen: 1,
            service_center: "+919032055002",
            sim_id: 1,
            status: -1,
            thread_id: 14,
            type: 1},
      {_id: 8,
              address: "KENYA POWER",
              body: "KPLC Token \nMtr No: 14140737793  \nToken: 3594-5632-4484-5405-5678\nDate: 2020-05-03 13:33:26 +0300\nUnits kWh:273.41\nAmount Ksh:6000.00\nToken Amount Ksh:4319.95\nVAT: 721.37\nFuel Energy Charge: 683.53\nForex Charge: -31.41\nEPRA Charge: 8.2\nWARMA Charge: 5.82\nREP Charge: 215.99\nInflation Adjustment: 76.55.",
              date: 1547840472000,
              date_sent: 1547840472000,
              error_code: 0,
              ipmsg_id: 0,
              locked: 0,
              m_size: 213,
              person: 0,
              protocol: 0,
              read: 1,
              reply_path_present: 0,
              seen: 1,
              service_center: "+919032055002",
              sim_id: 1,
              status: -1,
              thread_id: 14,
              type: 1},
{_id: 8,
              address: "KENYA POWER",
              body: "KPLC Token \nMtr No: 14140737793  \nToken: 1234-5632-4484-5405-1011\nDate: 2020-06-0913:33:26+0300\nUnits kWh:200.12\nAmount Ksh:3000.00\nToken Amount Ksh:4319.95\nVAT: 721.37\nFuel Energy Charge: 683.53\nForex Charge: -31.41\nEPRA Charge: 8.2\nWARMA Charge: 5.82\nREP Charge: 215.99\nInflation Adjustment: 76.55.",
              date: 1547840472000,
              date_sent: 1547840472000,
              error_code: 0,
              ipmsg_id: 0,
              locked: 0,
              m_size: 213,
              person: 0,
              protocol: 0,
              read: 1,
              reply_path_present: 0,
              seen: 1,
              service_center: "+919032055002",
              sim_id: 1,
              status: -1,
              thread_id: 14,
              type: 1},
{_id: 8,
            address: "KENYA POWER",
            body: "KPLC Token \nMtr No: 14140737793  \nToken: 1234-5632-4484-5405-1213\nDate: 2020-07-1015:33:26+0300\nUnits kWh:200.12\nAmount Ksh:5500.00\nToken Amount Ksh:4319.95\nVAT: 721.37\nFuel Energy Charge: 683.53\nForex Charge: -31.41\nEPRA Charge: 8.2\nWARMA Charge: 5.82\nREP Charge: 215.99\nInflation Adjustment: 76.55.",
            date: 1547840472000,
            date_sent: 1547840472000,
            error_code: 0,
            ipmsg_id: 0,
            locked: 0,
            m_size: 213,
            person: 0,
            protocol: 0,
            read: 1,
            reply_path_present: 0,
            seen: 1,
            service_center: "+919032055002",
            sim_id: 1,
            status: -1,
            thread_id: 14,
            type: 1}]

             

                   

    let regex = new RegExp("");
        
    for (let i = 0; i < messages.length; i++ ) {    
      
      tokens[i]  = messages[i]['body'];
      tokens[i]  = tokens[i].replace("KPLC Token","");
      //tokens[i]  = tokens[i].replace('\n',"',");
      
      //remove newline and add trailing qoutation
      regex = new RegExp(/^\s*/g);
      tokens[i]  = tokens[i].replace(regex ,"");

      //remove newline 
      regex = new RegExp(/\r?\n|\r/g);
      tokens[i]  = tokens[i].replace(regex ,"");

      //remove trailing fullstop or  whitespace
      regex = new RegExp(/.\s*$/g);
      tokens[i]  = tokens[i].replace(regex ,"");

      //insert lable quotations
      regex = new RegExp(/([a-zA-Z][\w\s]+)/g);
      tokens[i]  = tokens[i].replace(regex ,"\"$1\"");

      //insert value quotations
      regex = new RegExp(/\:\s*((?:(?!\").)*)/g);
      tokens[i]  = tokens[i].replace(regex ,":\"$1\",");

      // remove whitespace
      regex = new RegExp(/\s+/g);
      tokens[i]  = tokens[i].replace(regex ,"");

       // format date whitespace
       regex = new RegExp(/(\d\d\d\d-\d\d-\d\d)/g);
       tokens[i]  = tokens[i].replace(regex ,"$1 ");

       //remove trailing fullstop or  whitespace
      regex = new RegExp(/,\s*$/g);
      tokens[i]  = tokens[i].replace(regex ,"");


      //add curly braces
      tokens[i] = '{' + tokens[i] +'}';
      items[i]= JSON.parse(tokens[i]);
      items[i]["monthYear"]  = moment(items[i].Date).format("MMM-YYYY");
      

      }
      
      return this.tokenList.next(items);

       },  
        Error=>{
          alert(JSON.stringify(Error))
        });

                   
      //## END 0F SMS IF#//  
  };
}
